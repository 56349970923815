import {
  FetchMessagesFilters,
  FetchMessagesSort,
  FetchMessagesLimit,
  FetchMessagesGqlQuery,
  FetchMessagesCacheKey
} from '../../messagesTypes';

import {
  INITIAL_MESSAGES_FILTERS,
  INITIAL_MESSAGES_LIMIT,
  INITIAL_MESSAGES_SORT
} from '../../messagesConstants';

import {
  useInfiniteIndexQuery,
  InfiniteIndexQueryBaseNodeType,
  InfiniteIndexQueryOnSuccess
} from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

interface MessagesOptions<MessageItemType> {
  cacheKey: FetchMessagesCacheKey;
  query: FetchMessagesGqlQuery;
  initialFilters?: FetchMessagesFilters;
  initialSort?: FetchMessagesSort;
  initialLimit?: FetchMessagesLimit;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    onSuccess?: InfiniteIndexQueryOnSuccess<MessageItemType>;
  };
}

const scope = 'messages';

function useMessages<MessageItemType extends InfiniteIndexQueryBaseNodeType>({
  cacheKey,
  query,
  initialFilters = INITIAL_MESSAGES_FILTERS,
  initialSort = INITIAL_MESSAGES_SORT,
  initialLimit = INITIAL_MESSAGES_LIMIT,
  options = {}
}: MessagesOptions<MessageItemType>) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    clearItemsFilters,
    filterItems,
    sortItems,
    limitItems,
    changeItemsFilters
  } = useInfiniteIndexQuery<MessageItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    options,
    query,
    scope
  });

  return {
    messagesData: data,
    messages: items,
    messagesError: itemsError,
    messagesTotalCount: itemsTotalCount,
    messagesFetched: isFetched,
    messagesLoading: isLoading,
    messagesFetchingNextPage: isFetchingNextPage,
    messagesIsPlaceholderData: isPlaceholderData,
    messagesFilters: currentFilters,
    messagesSort: currentSort,
    messagesPage: currentPage,
    messagesLimit: currentLimit,
    hasNextMessagesPage: hasNextPage,
    updateMessageCache: updateItemCache,
    loadMoreMessages: loadMoreItems,
    filterMessages: filterItems,
    clearMessagesFilters: clearItemsFilters,
    sortMessages: sortItems,
    limitMessages: limitItems,
    changeMessagesFilters: changeItemsFilters
  };
}

export default useMessages;
