import { createContext, ReactNode, useContext } from 'react';

import {
  TaskNanoID,
  TaskProjectNanoID,
  TaskProjectTeamNanoID
} from '../../tasksTypes';

interface TaskContextType {
  taskNanoId: TaskNanoID;
  taskProjectNanoId: TaskProjectNanoID;
  companyNanoId: TaskProjectTeamNanoID;
}

const TaskContext = createContext<TaskContextType>({
  taskNanoId: null,
  taskProjectNanoId: null,
  companyNanoId: null
});

interface TaskContextProviderProps {
  children: ReactNode;
  taskNanoId: TaskNanoID;
  taskProjectNanoId: TaskProjectNanoID;
  companyNanoId: TaskProjectTeamNanoID;
}

export function TaskContextProvider({
  children,
  taskNanoId,
  taskProjectNanoId,
  companyNanoId
}: TaskContextProviderProps) {
  const taskContextValue = {
    taskNanoId,
    taskProjectNanoId,
    companyNanoId
  };

  return (
    <TaskContext.Provider value={taskContextValue}>
      {children}
    </TaskContext.Provider>
  );
}

export const useTaskContext = () => {
  return useContext(TaskContext);
};
