import { LexicalEditor, TextNode, $createTextNode, $getRoot } from 'lexical';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';
import escapeRegExp from 'lodash/escapeRegExp';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import compact from 'lodash/compact';

import { ID } from '../../../../../../types';

import { $createMentionNode, MentionNode } from '../../../../utils/MentionNode';
import { MentionTypeaheadOption } from '../../../../utils/MentionTypeaheadOption';

function splitTextNodeWithMentions(
  textNode: TextNode,
  initialMentionOptionsByNodeText: Record<string, MentionTypeaheadOption>,
  mentionsRegExp: RegExp
): Array<TextNode | MentionNode> | null {
  const textNodeText = textNode.__text as string;
  const splitNodes = [];
  const matchMentions = textNodeText.matchAll(mentionsRegExp);

  const matchMentionsArr = [...matchMentions];

  if (isEmpty(matchMentionsArr)) {
    return null;
  }
  forEach(matchMentionsArr, (mention, index) => {
    const prevMention = matchMentionsArr[index - 1];
    if (mention.index !== 0 && !prevMention) {
      const prevText = textNodeText.slice(0, mention.index);
      // add text node before first match
      splitNodes.push($createTextNode(prevText));
    }
    if (
      mention.index !== 0 &&
      prevMention &&
      mention.index - (prevMention.index + prevMention[0].length) >= 1
    ) {
      const prevText = textNodeText.slice(
        prevMention.index + prevMention[0].length,
        mention.index
      );
      // add text node between current match and previous match
      splitNodes.push($createTextNode(prevText));
    }

    // add mention node for match
    splitNodes.push(
      $createMentionNode(
        initialMentionOptionsByNodeText[mention[0]].id,
        mention[0]
      )
    );

    const nextMention = matchMentionsArr[index + 1];
    if (
      !nextMention &&
      textNodeText.length > mention[0].length + mention.index
    ) {
      const endText = textNode.__text.slice(
        mention.index + mention[0].length,
        textNodeText.length
      );
      // add text node after last match
      splitNodes.push($createTextNode(endText));
    }
  });

  return splitNodes;
}

function mentionsPluginInitializeMentions({
  editor,
  options,
  initialMentionIds
}: {
  editor: LexicalEditor;
  options: MentionTypeaheadOption[];
  initialMentionIds?: ID[];
}) {
  const initialMentionOptions = filter(options, ({ id }) =>
    includes(initialMentionIds, id)
  );

  if (isEmpty(initialMentionOptions)) {
    return;
  }

  const initialMentionOptionsByNodeText: Record<
    string,
    MentionTypeaheadOption
  > = fromPairs(
    initialMentionOptions.map((initialMentionOption) => {
      return [`@${initialMentionOption.name}`, initialMentionOption];
    })
  );

  const mentionsRegExp = new RegExp(
    keys(initialMentionOptionsByNodeText)
      .map((text) => `${escapeRegExp(text)}`)
      .join('|'),
    'g'
  );

  editor.update(() => {
    const root = $getRoot();

    const textNodes = root
      .getAllTextNodes()
      .filter((node) => node.__type === 'text') as TextNode[];

    const nodesToReplace = compact(
      textNodes.map((textNode) => {
        const replaceWith = splitTextNodeWithMentions(
          textNode,
          initialMentionOptionsByNodeText,
          mentionsRegExp
        );
        if (!replaceWith) {
          return null;
        }
        return { originalNode: textNode, replaceWith };
      })
    );

    if (!isEmpty(nodesToReplace)) {
      nodesToReplace.forEach(({ originalNode, replaceWith }) => {
        const originalNodeParent = originalNode.getParent();

        originalNodeParent?.splice(
          originalNode.getIndexWithinParent(),
          1,
          replaceWith
        );
      });
    }
  });
}

export default mentionsPluginInitializeMentions;
