import { FetchItemCacheKey, FetchItemsGqlQuery } from '../../../../types';
import {
  FetchSmartContractsCacheKey,
  FetchFinSmartContractsFilters,
  FetchSmartContractsSort,
  FetchSmartContractsPage,
  FetchSmartContractsLimit,
  FetchSmartContractsGqlQuery
} from '../../smartContractsTypes';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

import {
  INITIAL_SMART_CONTRACTS_FILTERS,
  INITIAL_SMART_CONTRACTS_LIMIT,
  INITIAL_SMART_CONTRACTS_PAGE,
  INITIAL_SMART_CONTRACTS_SORT
} from '../../smartContractsConstants';

interface SmartContractsWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemsGqlQuery;
}

interface SmartContractsWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

interface SmartContractsDefaultOptions {
  cacheKey: FetchSmartContractsCacheKey;
  query: FetchSmartContractsGqlQuery;
  initialFilters?: FetchFinSmartContractsFilters;
  initialSort?: FetchSmartContractsSort;
  initialPage?: FetchSmartContractsPage;
  initialLimit?: FetchSmartContractsLimit;
  options?: {
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    withoutPrefetch?: boolean;
  };
}

type SmartContractsOptions = SmartContractsDefaultOptions &
  (
    | SmartContractsWithPrefetchItemOptions
    | SmartContractsWithoutPrefetchItemOptions
  );

const scope = 'smartContracts';

function useFinPaginatedSmartContracts<FetchSmartContractsType>({
  cacheKey,
  initialFilters = INITIAL_SMART_CONTRACTS_FILTERS,
  initialSort = INITIAL_SMART_CONTRACTS_SORT,
  initialPage = INITIAL_SMART_CONTRACTS_PAGE,
  initialLimit = INITIAL_SMART_CONTRACTS_LIMIT,
  fetchItemCacheKey,
  fetchItemQuery,
  query,
  options = {}
}: SmartContractsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    updateItemCache,
    updateItemsCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems,
    prefetchItem
  } = useFinIndexQuery<FetchSmartContractsType>({
    cacheKey,
    scope,
    query,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    fetchItemCacheKey,
    fetchItemQuery,
    options
  });

  return {
    smartContractsData: data,
    smartContracts: items,
    smartContractsError: itemsError,
    smartContractsErrorMessage: itemsErrorMessage,
    smartContractsTotalCount: itemsTotalCount,
    smartContractsFetched: isFetched,
    smartContractsLoading: isLoading,
    smartContractsIsPlaceholderData: isPlaceholderData,
    smartContractsFilters: currentFilters,
    smartContractsSort: currentSort,
    smartContractsPage: currentPage,
    smartContractsLimit: currentLimit,
    updateSmartContractCache: updateItemCache,
    updateSmartContractsCache: updateItemsCache,
    filterSmartContracts: filterItems,
    changeSmartContractsFilters: changeItemsFilters,
    clearSmartContractsFilters: clearItemsFilters,
    sortSmartContracts: sortItems,
    paginateSmartContracts: paginateItems,
    limitSmartContracts: limitItems,
    prefetchSmartContracts: prefetchItems,
    prefetchSmartContract: prefetchItem
  };
}

export default useFinPaginatedSmartContracts;
