import { FetchProjectMemberInvitesSortTypes } from './projectMemberInvitesTypes';

export const INITIAL_PROJECT_MEMBER_INVITES_FILTERS = {};
export const INITIAL_PROJECT_MEMBER_INVITES_PAGE = 1;
export const INITIAL_PROJECT_MEMBER_INVITES_SORT = [
  FetchProjectMemberInvitesSortTypes.CREATED_AT_DESC
];
export const INITIAL_PROJECT_MEMBER_INVITES_LIMIT = 24;

export const enum ProjectMemberInvitesPermissions {
  READ_PROJECT_MEMBER_INVITES_INDEX_PAGE = 'read_project_member_invites_index_page',
  READ_ACCEPT_PROJECT_MEMBER_INVITE_BUTTON = 'read_accept_project_member_invite_button',
  READ_DECLINE_PROJECT_MEMBER_INVITE_BUTTON = 'read_decline_project_member_invite_button',
  READ_ACCEPT_SELECTED_PROJECT_MEMBER_INVITES_BUTTON = 'read_accept_selected_project_member_invites_button',
  READ_DECLINE_SELECTED_PROJECT_MEMBER_INVITES_BUTTON = 'read_decline_selected_project_member_invites_button',
  READ_ACCEPT_ALL_PROJECT_MEMBER_INVITES_BUTTON = 'read_accept_all_project_member_invites_button',
  READ_DECLINE_ALL_PROJECT_MEMBER_INVITES_BUTTON = 'read_decline_all_project_member_invites_button',
  READ_PROJECT_MEMBER_INVITES_COMPACT_INDEX_PAGE = 'read_project_member_invites_compact_index_page',
  READ_PROJECT_MEMBER_INVITES_TABLE_COMPANY_AVATAR_FIELD = 'read_project_member_invites_table_company_avatar_field',
  READ_PROJECT_MEMBER_INVITES_TABLE_PROJECT_NAME_FIELD = 'read_project_member_invites_table_project_name_field',
  READ_PROJECT_MEMBER_INVITES_TABLE_PROJECT_TEAM_FIELD = 'read_project_member_invites_table_project_team_field',
  READ_PROJECT_MEMBER_INVITES_TABLE_CREATED_AT_FIELD = 'read_project_member_invites_table_created_at_field',
  READ_PROJECT_MEMBER_INVITES_TABLE_USER_FIELD = 'read_project_member_invites_table_user_field',
  READ_PROJECT_MEMBER_INVITES_TABLE_PROJECT_PRICE_FIELD = 'read_project_member_invites_table_project_price_field'
}
