import { FetchTeamsUsersSortItem } from './teamsUsersTypes';

export const INITIAL_TEAMS_USERS_FILTERS = {};
export const INITIAL_TEAMS_USERS_PAGE = 1;
export const INITIAL_TEAMS_USERS_SORT = [
  FetchTeamsUsersSortItem.CREATED_AT_DESC
];
export const INITIAL_TEAMS_USERS_LIMIT = 24;

export const enum TeamsUsersPermissions {
  READ_COMPANY_MEMBERS_TABLE_SELECTED_FIELD = 'read_company_members_table_selected_field',
  READ_COMPANY_MEMBERS_TABLE_IMAGE_FIELD = 'read_company_members_table_image_field',
  READ_COMPANY_MEMBERS_TABLE_NAME_FIELD = 'read_company_members_table_name_field',
  READ_COMPANY_FIN_MEMBERS_TABLE_IMAGE_FIELD = 'read_company_fin_members_table_image_field',
  READ_COMPANY_FIN_MEMBERS_TABLE_NAME_FIELD = 'read_company_fin_members_table_name_field',
  READ_COMPANY_MEMBERS_TABLE_ID_FIELD = 'read_company_members_table_id_field',
  READ_COMPANY_MEMBERS_TABLE_EMAIL_FIELD = 'read_company_members_table_email_field',
  READ_COMPANY_MEMBERS_TABLE_JOB_TITLE_FIELD = 'read_company_members_table_job_title_field',
  READ_COMPANY_MEMBERS_TABLE_STATUS_FIELD = 'read_company_members_table_status_field',
  READ_COMPANY_MEMBERS_TABLE_LAST_ACTIVITY_DATE_FIELD = 'read_company_members_table_last_activity_date_field',
  READ_COMPANY_MEMBERS_TABLE_FINANCE_FIELD = 'read_company_members_table_finance_field',
  READ_COMPANY_MEMBERS_TABLE_LIBRARY_FIELD = 'read_company_members_table_library_field',
  READ_COMPANY_MEMBERS_TABLE_REQUIREMENTS_FIELD = 'read_company_members_table_requirements_field',
  CHANGE_COMPANY_MEMBERS_TABLE_FINANCE_FIELD = 'change_company_members_table_finance_field',
  CHANGE_COMPANY_MEMBERS_TABLE_LIBRARY_FIELD = 'change_company_members_table_library_field',
  CHANGE_COMPANY_MEMBERS_TABLE_REQUIREMENTS_FIELD = 'change_company_members_table_requirements_field',
  READ_COMPANY_MEMBERS_TABLE_DROPDOWN_FIELD = 'read_company_members_table_dropdown_field',
  READ_COMPANY_MEMBER_TABLE_PROFILE_LINK = 'read_company_member_table_profile_link',
  READ_COMPANY_MEMBER_TABLE_TASKS_LINK = 'read_company_member_table_tasks_link',
  READ_COMPANY_MEMBER_TABLE_ACCOUNTS_LINK = 'read_company_member_table_accounts_link',
  READ_COMPANY_MEMBERS_TABLE_BLOCK_USER_MODAL_BUTTON = 'read_company_members_table_block_user_modal_button',
  READ_COMPANY_MEMBERS_TABLE_UNBLOCK_USER_MODAL_BUTTON = 'read_company_members_table_unblock_user_modal_button',
  READ_COMPANY_MEMBERS_TABLE_REMOVE_USER_MODAL_BUTTON = 'read_company_members_table_remove_user_modal_button'
}
