import { gql } from 'graphql-request';

import {
  SmartContractID,
  SmartContractNanoID,
  SmartContractUUID,
  SmartContractSmartContractShareID,
  SmartContractSmartContractShareGeneralLedgerID,
  SmartContractSmartContractShareGeneralLedgerCompanyID,
  SmartContractSmartContractShareGeneralLedgerCompanyOwnerID,
  SmartContractSmartContractShareGeneralLedgerCompanyOwnerNanoID,
  SmartContractSmartContractShareGeneralLedgerCompanyOwnerFullName,
  SmartContractSmartContractShareGeneralLedgerCompanyOwnerImageFile,
  SmartContractSmartContractShareGeneralLedgerName
} from '../smartContractsTypes';
import { GeneralLedgerGeneralLedgerType } from '../../generalLedgers/generalLedgersTypes';
import { TeamTeamType } from '../../teams/teamsTypes';

export interface FetchTaskSmartContractsSharesQueryResponse {
  id: SmartContractID;
  uuid: SmartContractUUID;
  nanoId: SmartContractNanoID;
  smartContractShares: {
    id: SmartContractSmartContractShareID;
    generalLedger: {
      id: SmartContractSmartContractShareGeneralLedgerID;
      name: SmartContractSmartContractShareGeneralLedgerName;
      generalLedgerType: GeneralLedgerGeneralLedgerType;
      company: {
        id: SmartContractSmartContractShareGeneralLedgerCompanyID;
        companyType: TeamTeamType;
        owner: {
          id: SmartContractSmartContractShareGeneralLedgerCompanyOwnerID;
          nanoId: SmartContractSmartContractShareGeneralLedgerCompanyOwnerNanoID;
          blocked: boolean;
          fullName: SmartContractSmartContractShareGeneralLedgerCompanyOwnerFullName;
          image: {
            file: SmartContractSmartContractShareGeneralLedgerCompanyOwnerImageFile;
          };
        };
      };
    };
  }[];
}

export const FETCH_TASK_SMART_CONTRACTS_SHARES_QUERY = gql`
  query TaskSmartContractsShares(
    $filters: SmartContractsFilters
    $sort: [SmartContractsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    smartContracts(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        nanoId
        smartContractShares {
          id
          generalLedger {
            id
            name
            generalLedgerType
            company {
              id
              companyType
              owner {
                id
                nanoId
                blocked
                fullName
                image {
                  file
                }
              }
            }
          }
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
