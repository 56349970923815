import { gql } from 'graphql-request';

import { MessageResultVersion, MessageUUID } from '../messagesTypes';

export interface UpdateMessageResultVersionRecordType {
  resultVersion: MessageResultVersion;
  uuid: MessageUUID;
}

export const UPDATE_MESSAGE_RESULT_VERSION_QUERY = gql`
  mutation UpdateMessageResultVersion($uuid: ID!, $resultVersion: Integer!) {
    updateMessageResultVersion(
      input: { uuid: $uuid, resultVersion: $resultVersion }
    ) {
      errors {
        fullMessages
      }
      recordUuid
      status
      record {
        uuid
        resultVersion
      }
    }
  }
`;
