import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';

import { MessageCommandsEnum } from '../../../../main/messages/messagesTypes';

export default class CommandTypeaheadOption extends MenuOption {
  description?: string;
  id: MessageCommandsEnum;
  title: string;

  constructor(id: MessageCommandsEnum, title: string, description?: string) {
    super(id);
    this.id = id;
    this.title = title;
    this.description = description;
  }
}
