import { gql } from 'graphql-request';

import { TaskNanoID, TaskUUID } from '../tasksTypes';

export interface SubmitResultsToTaskQueryResponse {
  uuid: TaskUUID;
  nanoId: TaskNanoID;
}

export const SUBMIT_RESULTS_TO_TASK_QUERY = gql`
  mutation SubmitResultsToTask(
    $uuid: ID!
    $fileAttachmentIds: [ID!]!
    $hours: Integer
    $minutes: Integer
    $body: String
    $mentionedUserIds: [ID!]
  ) {
    submitResultsToTask(
      input: {
        uuid: $uuid
        fileAttachmentIds: $fileAttachmentIds
        hours: $hours
        minutes: $minutes
        body: $body
        mentionedUserIds: $mentionedUserIds
      }
    ) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
      }
      errors {
        fullMessages
      }
    }
  }
`;
