import { useCallback, useMemo, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useBasicTypeaheadTriggerMatch } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { $getSelection, TextNode } from 'lexical';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';

import { MessageCommandTypesEnum } from '../../../../../../main/messages/messagesTypes';

import { useTranslate } from '../../../../../../common/hooks/useTranslate';
import { useMessageCommandOptions } from '../useMessageCommandOptions';
import { useMessageCommand } from '../../../../../../main/messages/hooks/useMessageCommand';

import { CommandTypeaheadOption } from '../../../../utils/CommandTypeaheadOption';

import { messageCommandsData } from '../../../../../../main/messages/messagesConstants';

function useCommandsPlugin() {
  const { t } = useTranslate();

  const commandOptions = useMessageCommandOptions();

  const [editor] = useLexicalComposerContext();

  const [queryString, setQueryString] = useState<string | null>(null);

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0
  });

  const { setMessageCommand } = useMessageCommand();

  const handleSelectOption = useCallback(
    (
      selectedOption: CommandTypeaheadOption,
      nodeToRemove: TextNode | null,
      closeMenu: () => void
    ) => {
      editor.update(() => {
        nodeToRemove?.remove();

        if (
          messageCommandsData[selectedOption.id]?.type ===
          MessageCommandTypesEnum.INSERT_TEXT
        ) {
          $getSelection()?.insertText(
            t(messageCommandsData[selectedOption.id]?.i18nUserContent)
          );
        } else {
          setMessageCommand(selectedOption.id);
        }

        closeMenu();
      });
    },
    [editor, setMessageCommand, t]
  );

  const filteredOptions = useMemo<CommandTypeaheadOption[]>(() => {
    if (!queryString) {
      return commandOptions;
    }

    return filter(commandOptions, (option) =>
      includes(toLower(option.title), toLower(queryString))
    );
  }, [commandOptions, queryString]);

  return {
    checkForSlashTriggerMatch,
    filteredOptions,
    handleSelectOption,
    setQueryString
  };
}

export default useCommandsPlugin;
