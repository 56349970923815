import { LexicalTypeaheadMenuPlugin } from '@lexical/react/LexicalTypeaheadMenuPlugin';

import { useCommandsPlugin } from './hooks/useCommandsPlugin';

import { CommandsMenuItem } from '../CommandsMenuItem';
import { PopoverWithPortal } from '../../../Popover';

import { CommandTypeaheadOption } from '../../utils/CommandTypeaheadOption';
import { Translate } from '../../../Translate';
import { PopoverPlacement } from '../../../Popover/popoverConstants';

import { messagesKeys } from '../../../../locales/keys';

function CommandsPlugin() {
  const {
    checkForSlashTriggerMatch,
    filteredOptions,
    handleSelectOption,
    setQueryString
  } = useCommandsPlugin();

  return (
    <LexicalTypeaheadMenuPlugin<CommandTypeaheadOption>
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
      ) =>
        anchorElementRef && filteredOptions?.length ? (
          <PopoverWithPortal
            className="min-w-48 z-20 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700 max-h-60"
            placement={PopoverPlacement.TOP_START}
            referenceElement={anchorElementRef.current}
          >
            <div className="px-2 pt-2 pb-1" data-toggle-item="item-main-0 ">
              <div className="px-2 text-gray-400 dark:text-gray-400 text-xs leading-5">
                <Translate id={messagesKeys.runCommand} />
              </div>
              <hr className="flex-1 dark:border-gray-600" />
            </div>

            <ul>
              {filteredOptions.map((option, i: number) => (
                <CommandsMenuItem
                  index={i}
                  isSelected={selectedIndex === i}
                  onClick={() => {
                    setHighlightedIndex(i);
                    selectOptionAndCleanUp(option);
                  }}
                  onMouseEnter={() => {
                    setHighlightedIndex(i);
                  }}
                  key={option.key}
                  option={option}
                />
              ))}
            </ul>
          </PopoverWithPortal>
        ) : null
      }
      onQueryChange={setQueryString}
      onSelectOption={handleSelectOption}
      options={filteredOptions}
      triggerFn={checkForSlashTriggerMatch}
    />
  );
}

export default CommandsPlugin;
