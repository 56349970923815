import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';

import { ID } from '../../../../types';

export default class MentionTypeaheadOption extends MenuOption {
  id: ID;
  name: string;
  image?: string;

  constructor(id: ID, name: string, image?: string) {
    super(id);
    this.id = id;
    this.name = name;
    this.image = image;
  }
}
