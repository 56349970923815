import { FetchItemCacheKey, FetchItemsGqlQuery } from '../../../../types';

import {
  FetchProjectMemberInvitesGqlQuery,
  FetchProjectMemberInvitesCacheKey,
  FetchProjectMemberInvitesFilters,
  FetchProjectMemberInvitesSort,
  FetchProjectMemberInvitesPage,
  FetchProjectMemberInvitesLimit
} from '../../projectMemberInvitesTypes';

import { useReadIndexQuery } from '../../../common/hooks/base/reactQuery/useReadIndexQuery';

import {
  INITIAL_PROJECT_MEMBER_INVITES_FILTERS,
  INITIAL_PROJECT_MEMBER_INVITES_LIMIT,
  INITIAL_PROJECT_MEMBER_INVITES_PAGE,
  INITIAL_PROJECT_MEMBER_INVITES_SORT
} from '../../projectMemberInvitesConstants';

interface ProjectMemberInvitesDefaultOptions {
  cacheKey: FetchProjectMemberInvitesCacheKey;
  query: FetchProjectMemberInvitesGqlQuery;
  initialFilters?: FetchProjectMemberInvitesFilters;
  initialSort?: FetchProjectMemberInvitesSort;
  initialPage?: FetchProjectMemberInvitesPage;
  initialLimit?: FetchProjectMemberInvitesLimit;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    withoutPrefetch?: boolean;
  };
}

interface ProjectMemberInvitesWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemsGqlQuery;
}

interface ProjectMemberInvitesWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

type ProjectMemberInvitesOptions = ProjectMemberInvitesDefaultOptions &
  (
    | ProjectMemberInvitesWithPrefetchItemOptions
    | ProjectMemberInvitesWithoutPrefetchItemOptions
  );

const scope = 'projectMemberInvites';

function usePaginatedProjectMemberInvites<ItemType>({
  cacheKey,
  query,
  initialFilters = INITIAL_PROJECT_MEMBER_INVITES_FILTERS,
  initialSort = INITIAL_PROJECT_MEMBER_INVITES_SORT,
  initialPage = INITIAL_PROJECT_MEMBER_INVITES_PAGE,
  initialLimit = INITIAL_PROJECT_MEMBER_INVITES_LIMIT,
  fetchItemCacheKey,
  fetchItemQuery,
  options = {}
}: ProjectMemberInvitesOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems,
    prefetchItem
  } = useReadIndexQuery<ItemType>({
    scope,
    query,
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    fetchItemCacheKey,
    fetchItemQuery,
    options
  });

  return {
    projectMemberInvitesData: data,
    projectMemberInvites: items,
    projectMemberInvitesError: itemsError,
    projectMemberInvitesTotalCount: itemsTotalCount,
    projectMemberInvitesFetched: isFetched,
    projectMemberInvitesLoading: isLoading,
    projectMemberInvitesIsPlaceholderData: isPlaceholderData,
    projectMemberInvitesFilters: currentFilters,
    projectMemberInvitesSort: currentSort,
    projectMemberInvitesPage: currentPage,
    projectMemberInvitesLimit: currentLimit,
    filterProjectMemberInvites: filterItems,
    changeProjectMemberInvitesFilters: changeItemsFilters,
    clearProjectMemberInvitesFilters: clearItemsFilters,
    sortProjectMemberInvites: sortItems,
    paginateProjectMemberInvites: paginateItems,
    limitProjectMemberInvites: limitItems,
    prefetchProjectMemberInvites: prefetchItems,
    prefetchProjectMemberInvite: prefetchItem
  };
}

export default usePaginatedProjectMemberInvites;
