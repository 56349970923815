import React from 'react';

import {
  ErrorMessage,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../types';
import { TaskNanoID, TaskResultsFields } from '../../../tasksTypes';
import { SubmitResultsToTaskFormData } from './SubmitResultsToTaskForm.types';
import { ProjectNanoID } from '../../../../projects/projectsTypes';
import { TeamNanoID } from '../../../../teams/teamsTypes';
import {
  DropzoneHelperFileIds,
  DropzoneHelperFiles
} from '../../../../../helpers/dropzone/DropzoneHelper';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import {
  DropzoneField,
  DropzoneFieldRequiredProps
} from '../../../../../helpers/FormFields/DropzoneField';
import { LexicalEditorField } from '../../../../../helpers/FormFields/LexicalEditorField';
import { Translate } from '../../../../../helpers/Translate';

import { formsFields } from '../../../../../locales/keys';

interface SubmitResultsToTaskFormProps {
  form: string;
  isLoading?: IsLoading;
  registerHours: RegisterFormFieldType<HTMLInputElement>;
  registerMinutes: RegisterFormFieldType<HTMLInputElement>;
  initialFiles?: DropzoneHelperFiles;
  initialFileIds?: DropzoneHelperFileIds;
  fileAttachmentIdsValidationError: ErrorMessage;
  hoursValidationError: ErrorMessage;
  minutesValidationError: ErrorMessage;
  onChangeFiles?: (files: DropzoneHelperFiles) => void;
  projectNanoId?: ProjectNanoID;
  teamNanoId?: TeamNanoID;
  taskNanoId?: TaskNanoID;
}

function SubmitResultsToTaskForm({
  form,
  control,
  isLoading,
  registerHours,
  registerMinutes,
  fileAttachmentIdsValidationError,
  hoursValidationError,
  minutesValidationError,
  initialFiles,
  initialFileIds,
  onChangeFiles,
  projectNanoId,
  teamNanoId,
  taskNanoId
}: SubmitResultsToTaskFormProps &
  DropzoneFieldRequiredProps<SubmitResultsToTaskFormData>) {
  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className="p-4">
        <DropzoneField
          withoutTabs
          control={control}
          name={TaskResultsFields.FILE_ATTACHMENT_IDS}
          error={fileAttachmentIdsValidationError}
          type="fileAttachments"
          labelFor="submit-task-results-files"
          disabled={isLoading}
          initialFiles={initialFiles}
          initialFileIds={initialFileIds}
          onChangeFiles={onChangeFiles}
        />
      </div>
      <div className="p-4">
        <div className="flex flex-col gap-4">
          <div className="flex gap-3 flex-wrap">
            <div className="w-full sm:w-auto flex-col flex-1 self-stretch">
              <div className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                <Translate id={formsFields.description} />
              </div>

              <LexicalEditorField
                control={control}
                disabled={isLoading}
                name={TaskResultsFields.LEXICAL}
                wrapperClassName="h-40 relative flex-1 sm:min-w-80 cursor-default"
                projectNanoId={projectNanoId}
                companyNanoId={teamNanoId}
                taskNanoId={taskNanoId}
              />
            </div>
          </div>
          <div className="flex gap-3 flex-wrap">
            <div className="w-full sm:w-auto sm:flex-1">
              <InputField
                name={registerHours.name}
                onChange={registerHours.onChange}
                ref={registerHours.ref}
                i18nLabel={formsFields.hours}
                placeholder="0-7"
                inputWrapperClassName="relative mt-1"
                error={hoursValidationError}
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                inputClassName="basic-input number-appearance-none"
                showErrorIcon={false}
                disabled={isLoading}
                type="number"
              />
            </div>
            <div className="w-full sm:w-auto sm:flex-1">
              <InputField
                name={registerMinutes.name}
                onChange={registerMinutes.onChange}
                ref={registerMinutes.ref}
                i18nLabel={formsFields.minutes}
                placeholder="0-59"
                error={minutesValidationError}
                inputWrapperClassName="relative mt-1"
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                inputClassName="basic-input number-appearance-none"
                showErrorIcon={false}
                disabled={isLoading}
                type="number"
              />
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default SubmitResultsToTaskForm;
