import { useMemo } from 'react';
import compact from 'lodash/compact';

import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';
import { useTranslate } from '../../../../../../common/hooks/useTranslate';

import { MessageCommandsEnum } from '../../../../../../main/messages/messagesTypes';

import { CommandTypeaheadOption } from '../../../../utils/CommandTypeaheadOption';

import {
  messageCommandsData,
  MessagesPermissions
} from '../../../../../../main/messages/messagesConstants';

function useMessageCommandOptions() {
  const currentUser = useCurrentUser();

  const { t } = useTranslate();

  return useMemo<CommandTypeaheadOption[]>(
    () =>
      compact([
        currentUser.hasPermissions(
          MessagesPermissions.READ_MESSAGE_CHAT_GPT_COMMAND
        )
          ? MessageCommandsEnum.CHAT_GPT
          : null,
        currentUser.hasPermissions(
          MessagesPermissions.READ_MESSAGE_CHECK_GRAMMAR_COMMAND
        )
          ? MessageCommandsEnum.CHECK_GRAMMAR
          : null,
        currentUser.hasPermissions(
          MessagesPermissions.READ_MESSAGE_TRANSLATE_COMMAND
        )
          ? MessageCommandsEnum.TRANSLATE
          : null,
        currentUser.hasPermissions(
          MessagesPermissions.READ_MESSAGE_CASUAL_TRANSLATE_COMMAND
        )
          ? MessageCommandsEnum.CASUAL_TRANSLATE
          : null,
        currentUser.hasPermissions(
          MessagesPermissions.READ_MESSAGE_FRIENDLY_TRANSLATE_COMMAND
        )
          ? MessageCommandsEnum.FRIENDLY_TRANSLATE
          : null,
        currentUser.hasPermissions(
          MessagesPermissions.READ_MESSAGE_TODO_LIST_FROM_PDF_COMMAND
        )
          ? MessageCommandsEnum.TODO_LIST_FROM_PDF
          : null,
        currentUser.hasPermissions(
          MessagesPermissions.READ_MESSAGE_THANKS_A_LOT_COMMAND
        )
          ? MessageCommandsEnum.THANKS_A_LOT
          : null
      ]).map(
        (command) =>
          new CommandTypeaheadOption(
            command,
            t(messageCommandsData[command].i18nTitle),
            t(messageCommandsData[command].i18nDescription)
          )
      ),
    [currentUser, t]
  );
}

export default useMessageCommandOptions;
