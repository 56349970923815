import { gql } from 'graphql-request';

import { MayBe } from '../../../types';

import {
  ProjectMemberInviteUUID,
  ProjectMemberInviteCreatedAt,
  ProjectMemberInviteUserID,
  ProjectMemberInviteUserUUID,
  ProjectMemberInviteUserNanoID,
  ProjectMemberInviteUserFullName,
  ProjectMemberInviteUserFinanceRoleName,
  ProjectMemberInviteUserImageUUID,
  ProjectMemberInviteUserImageFile,
  ProjectMemberInviteProjectID,
  ProjectMemberInviteProjectUUID,
  ProjectMemberInviteProjectNanoID,
  ProjectMemberInviteUserClient,
  ProjectMemberInviteUserCurrentTeamNanoID,
  ProjectMemberInviteUserBlocked
} from '../projectMemberInvitesTypes';

export interface FetchProjectInvitedMembersQueryResponse {
  id: ProjectMemberInviteUserID;
  uuid: ProjectMemberInviteUUID;
  createdAt: ProjectMemberInviteCreatedAt;
  invitedUser: {
    id: ProjectMemberInviteUserID;
    uuid: ProjectMemberInviteUserUUID;
    nanoId: ProjectMemberInviteUserNanoID;
    fullName: ProjectMemberInviteUserFullName;
    financeRoleName: ProjectMemberInviteUserFinanceRoleName;
    client: ProjectMemberInviteUserClient;
    blocked: ProjectMemberInviteUserBlocked;
    currentTeam: MayBe<{
      nanoId: ProjectMemberInviteUserCurrentTeamNanoID;
    }>;
    image: {
      uuid: ProjectMemberInviteUserImageUUID;
      file: ProjectMemberInviteUserImageFile;
    };
  };
  user: {
    id: ProjectMemberInviteUserID;
    uuid: ProjectMemberInviteUserUUID;
    nanoId: ProjectMemberInviteUserNanoID;
    fullName: ProjectMemberInviteUserFullName;
    financeRoleName: ProjectMemberInviteUserFinanceRoleName;
    client: ProjectMemberInviteUserClient;
    currentTeam: MayBe<{
      nanoId: ProjectMemberInviteUserCurrentTeamNanoID;
    }>;
    image: {
      uuid: ProjectMemberInviteUserImageUUID;
      file: ProjectMemberInviteUserImageFile;
    };
  };
  project: {
    id: ProjectMemberInviteProjectID;
    uuid: ProjectMemberInviteProjectUUID;
    nanoId: ProjectMemberInviteProjectNanoID;
  };
}

export const FETCH_PROJECT_INVITED_MEMBERS_QUERY = gql`
  query ProjectInvitedMembers(
    $filters: ProjectMemberInvitesFilters
    $sort: [ProjectMemberInvitesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    projectMemberInvites(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        createdAt
        invitedUser {
          id
          uuid
          nanoId
          fullName
          financeRoleName
          client
          blocked
          currentTeam {
            nanoId
          }
          image {
            uuid
            file
          }
        }
        user {
          id
          uuid
          nanoId
          fullName
          financeRoleName
          client
          currentTeam {
            nanoId
          }
          image {
            uuid
            file
          }
        }
        project {
          id
          uuid
          nanoId
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
