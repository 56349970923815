import { useReactQueryState } from '../../../common/hooks/base/reactQuery/useReactQueryState';

import { MessageCommandsEnum } from '../../messagesTypes';

const messageCommandsCacheKey = 'message-commands-cache-key';

function useMessageCommand() {
  const { value, setValue, clearValue } =
    useReactQueryState<MessageCommandsEnum | null>(messageCommandsCacheKey);

  return {
    messageCommand: value,
    setMessageCommand: setValue,
    clearMessageCommand: clearValue
  };
}

export default useMessageCommand;
