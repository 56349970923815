import type { Spread } from 'lexical';

import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  TextNode
} from 'lexical';
import { ID } from '../../../../types';

export type SerializedMentionNode = Spread<
  {
    mentionId: ID;
    mentionName: string;
    type: 'mention';
    version: 1;
  },
  SerializedTextNode
>;

function convertMentionElement(
  domNode: HTMLElement
): DOMConversionOutput | null {
  const textContent = domNode.textContent;

  if (textContent !== null) {
    const node = $createMentionNode(domNode.id, textContent);
    return {
      node
    };
  }

  return null;
}

// const mentionStyle = 'background-color: rgba(24, 119, 232, 0.2)';
class MentionNode extends TextNode {
  __id: ID;
  __mention: string;

  static getType(): string {
    return 'mention';
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__id, node.__mention, node.__text, node.__key);
  }
  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    const node = $createMentionNode(
      serializedNode.mentionId,
      serializedNode.mentionName
    );
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(
    mentionId: ID,
    mentionName: string,
    text?: string,
    key?: NodeKey
  ) {
    super(text ?? mentionName, key);
    this.__id = mentionId;
    this.__mention = mentionName;
  }

  exportJSON(): SerializedMentionNode {
    return {
      ...super.exportJSON(),
      mentionId: this.__id,
      mentionName: this.__mention,
      type: 'mention',
      version: 1
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    // dom.style.cssText = mentionStyle;
    // dom.className = 'mention';
    dom.className =
      'dark:bg-green-700 bg-green-100 text-green-800 dark:text-white rounded-md px-1 py-0.5';
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-mention', 'true');
    element.textContent = this.__text;
    element.id = this.__id;
    return { element };
  }

  isSegmented(): false {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-mention')) {
          return null;
        }
        return {
          conversion: convertMentionElement,
          priority: 1
        };
      }
    };
  }

  isTextEntity(): true {
    return true;
  }

  isToken(): true {
    return true;
  }
}

export function $createMentionNode(
  mentionId: ID,
  mentionName: string
): MentionNode {
  const mentionNode = new MentionNode(mentionId, mentionName);
  mentionNode.setMode('segmented').toggleDirectionless();
  return mentionNode;
}

export function $isMentionNode(
  node: LexicalNode | null | undefined
): node is MentionNode {
  return node instanceof MentionNode;
}

export default MentionNode;
