import { useCallback, useMemo, useState } from 'react';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';
import toLower from 'lodash/toLower';
import uniqBy from 'lodash/uniqBy';
import flatMap from 'lodash/flatMap';

import { ID } from '../../../../types';
import { UserFullName } from '../../../../main/users/usersTypes';
import { ProjectNanoID } from '../../../../main/projects/projectsTypes';
import {
  TeamNanoID,
  TeamTeamTypeEnum
} from '../../../../main/teams/teamsTypes';
import { TaskNanoID } from '../../../../main/tasks/tasksTypes';

import {
  FETCH_PROJECT_MEMBERS_QUERY,
  FetchProjectMembersQueryResponse
} from '../../../../main/projectMembers/queries/projectMembers.query';
import {
  FETCH_PROJECT_INVITED_MEMBERS_QUERY,
  FetchProjectInvitedMembersQueryResponse
} from '../../../../main/projectMemberInvites/queries/fetchProjectInvitedMembers.query';
import {
  FETCH_TASK_MEMBER_INVITES_QUERY,
  FetchTaskMemberInvitesQueryResponse
} from '../../../../main/taskMemberInvites/queries/fetchTaskMemberInvites.query';
import {
  FETCH_TEAMS_USERS_QUERY,
  FetchTeamsUsersQueryResponse
} from '../../../../main/teamsUsers/queries/fetchTeamsUsers.query';
import {
  FETCH_TASK_SMART_CONTRACTS_SHARES_QUERY,
  FetchTaskSmartContractsSharesQueryResponse
} from '../../../../main/smartContracts/queries/fetchTaskSmartContractsShares.query';

import { useProjectContext } from '../../../../main/projects/hooks/useProjectContext';
import { useTaskContext } from '../../../../main/tasks/hooks/useTaskContext';
import { useProjectMembers } from '../../../../main/projectMembers/hooks/useProjectMembers';
import { useTeamsUsers } from '../../../../main/teamsUsers/hooks/useTeamsUsers';
import { usePaginatedTaskMemberInvites } from '../../../../main/taskMemberInvites/hooks/usePaginatedTaskMemberInvites';
import { usePaginatedProjectMemberInvites } from '../../../../main/projectMemberInvites/hooks/usePaginatedProjectMemberInvites';
import { useFinPaginatedSmartContracts } from '../../../../main/smartContracts/hooks/useFinPaginatedSmartContracts';

import { MentionsPlugin } from '../MentionsPlugin';
import { MentionTypeaheadOption } from '../../utils/MentionTypeaheadOption';

import { TeamsUserCache } from '../../../../main/teamsUsers/TeamsUserCache';
import { ProjectCache } from '../../../../main/projects/ProjectCache';
import { TaskCache } from '../../../../main/tasks/TaskCache';
import { SmartContractCache } from '../../../../main/smartContracts/SmartContractCache';

import { GeneralLedgerGeneralLedgerTypes } from '../../../../main/generalLedgers/generalLedgersTypes';

interface ProjectTeamMentionsPluginProps {
  companyNanoId?: TeamNanoID;
  projectNanoId?: ProjectNanoID;
  taskNanoId?: TaskNanoID;
  initialMentionIds?: ID[];
}

function ProjectTeamMentionsPlugin({
  companyNanoId: propsCompanyNanoId,
  projectNanoId: propsProjectNanoId,
  taskNanoId: propsTaskNanoId,
  initialMentionIds
}: ProjectTeamMentionsPluginProps) {
  const {
    projectNanoId: projectContextProjectNanoId,
    companyNanoId: projectContextCompanyNanoId
  } = useProjectContext();

  const {
    taskProjectNanoId: taskContextProjectNanoId,
    companyNanoId: taskContextCompanyNanoId,
    taskNanoId: taskNanoIdFromContext
  } = useTaskContext();

  const projectNanoId =
    propsProjectNanoId ||
    taskContextProjectNanoId ||
    projectContextProjectNanoId;

  const companyNanoId =
    propsCompanyNanoId ||
    taskContextCompanyNanoId ||
    projectContextCompanyNanoId;

  const taskNanoId = propsTaskNanoId || taskNanoIdFromContext;

  const { projectMembers } =
    useProjectMembers<FetchProjectMembersQueryResponse>({
      cacheKey: ProjectCache.projectMembersCacheKey(projectNanoId),
      query: FETCH_PROJECT_MEMBERS_QUERY,
      initialFilters: { projectNanoId },
      initialLimit: 1000,
      options: {
        enabled: !!projectNanoId,
        enabledPlaceholder: !!projectNanoId,
        withoutPrefetch: true
      }
    });

  const { projectMemberInvites } =
    usePaginatedProjectMemberInvites<FetchProjectInvitedMembersQueryResponse>({
      cacheKey: ProjectCache.projectInvitesMembersCacheKey(projectNanoId),
      query: FETCH_PROJECT_INVITED_MEMBERS_QUERY,
      initialFilters: { projectNanoId },
      options: {
        enabled: !!projectNanoId,
        enabledPlaceholder: !!projectNanoId,
        withoutPrefetch: true
      }
    });

  const { smartContracts } =
    useFinPaginatedSmartContracts<FetchTaskSmartContractsSharesQueryResponse>({
      query: FETCH_TASK_SMART_CONTRACTS_SHARES_QUERY,
      cacheKey: taskNanoId
        ? SmartContractCache.taskSmartContractsSharesCacheKey(taskNanoId)
        : SmartContractCache.projectSmartContractsSharesCacheKey(projectNanoId),
      initialFilters: taskNanoId
        ? { taskNanoId: { eq: taskNanoId } }
        : { projectNanoId: { eq: projectNanoId } },
      options: {
        enabled: !!taskNanoId || !!projectNanoId,
        enabledPlaceholder: !!taskNanoId || !!projectNanoId,
        withoutPrefetch: true
      }
    });

  const { taskMemberInvites } =
    usePaginatedTaskMemberInvites<FetchTaskMemberInvitesQueryResponse>({
      cacheKey: TaskCache.taskMembersInvitesCacheKey(taskNanoId),
      query: FETCH_TASK_MEMBER_INVITES_QUERY,
      initialFilters: { taskNanoId: taskNanoId },
      initialLimit: 1000,
      options: {
        enabled: !!taskNanoId,
        enabledPlaceholder: !!taskNanoId,
        withoutPrefetch: true
      }
    });

  const { teamsUsers } = useTeamsUsers<FetchTeamsUsersQueryResponse>({
    cacheKey: TeamsUserCache.teamTeamsUsersCacheKey(companyNanoId),
    query: FETCH_TEAMS_USERS_QUERY,
    initialFilters: { teamNanoId: { eq: companyNanoId } },
    initialLimit: 1000,
    options: {
      enabled: !!companyNanoId,
      enabledPlaceholder: !!companyNanoId,
      withoutPrefetch: true
    }
  });

  const users = useMemo(() => {
    const shares = filter(
      flatMap(
        smartContracts,
        (smartContract) => smartContract.smartContractShares
      ),
      (share) =>
        share.generalLedger.generalLedgerType ===
          GeneralLedgerGeneralLedgerTypes.DEFAULT &&
        share.generalLedger.company.companyType === TeamTeamTypeEnum.WORKERS
    );

    return uniqBy(
      filter(
        [
          ...map(projectMembers, 'user'),
          ...map(teamsUsers, 'user'),
          ...map(projectMemberInvites, 'invitedUser'),
          ...map(taskMemberInvites, 'invitedUser'),
          ...map(shares, (share) => share.generalLedger.company.owner)
        ],
        (member) => !member.blocked
      ),
      'id'
    );
  }, [
    projectMemberInvites,
    projectMembers,
    smartContracts,
    taskMemberInvites,
    teamsUsers
  ]);

  const [fullName, setFullName] = useState<UserFullName>(null);

  const options = useMemo<MentionTypeaheadOption[]>(
    () =>
      filter(users, (user) =>
        includes(toLower(user.fullName), toLower(fullName))
      )?.map(
        (result) =>
          new MentionTypeaheadOption(
            result.id,
            result.fullName,
            result.image?.file
          )
      ),
    [users, fullName]
  );

  const handleQueryChange = useCallback<(queryString: string) => void>(
    (queryString) => setFullName(queryString),
    []
  );

  return (
    <MentionsPlugin
      options={options}
      initialMentionIds={initialMentionIds}
      onQueryChange={handleQueryChange}
    />
  );
}

export default ProjectTeamMentionsPlugin;
