import { LexicalEditorHelper } from '../../../LexicalEditorHelper';

import { ClassName, IsDisabled } from '../../../../types';
import { ProjectNanoID } from '../../../../main/projects/projectsTypes';
import { TeamNanoID } from '../../../../main/teams/teamsTypes';
import { TaskNanoID } from '../../../../main/tasks/tasksTypes';

import {
  LexicalFieldRef,
  LexicalFieldValueType,
  OnChangeLexicalFieldValue
} from '../LexicalEditorField';

interface LexicalEditorFieldControlProps {
  autoFocus?: boolean;
  companyNanoId?: TeamNanoID;
  disabled?: IsDisabled;
  editorRef?: LexicalFieldRef;
  inputClassName?: ClassName;
  name: string;
  onBlur?: (e: FocusEvent) => void;
  onChange: OnChangeLexicalFieldValue;
  onFocus?: (e: FocusEvent) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  projectNanoId?: ProjectNanoID;
  taskNanoId?: TaskNanoID;
  value: LexicalFieldValueType;
  withFormatting?: boolean;
  wrapperClassName?: ClassName;
}
function LexicalEditorFieldControl({
  autoFocus = false,
  companyNanoId,
  disabled,
  editorRef,
  inputClassName,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  projectNanoId,
  taskNanoId,
  value,
  withFormatting,
  wrapperClassName
}: LexicalEditorFieldControlProps) {
  return (
    <LexicalEditorHelper
      autoFocus={autoFocus}
      companyNanoId={companyNanoId}
      disabled={disabled}
      editorRef={editorRef}
      inputClassName={inputClassName}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      projectNanoId={projectNanoId}
      taskNanoId={taskNanoId}
      value={value}
      withFormatting={withFormatting}
      wrapperClassName={wrapperClassName}
    />
  );
}

export default LexicalEditorFieldControl;
