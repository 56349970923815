import cl from 'classnames';

import { CommandTypeaheadOption } from '../../utils/CommandTypeaheadOption';

interface CommandsMenuItemProps {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: CommandTypeaheadOption;
}

function CommandsMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option
}: CommandsMenuItemProps) {
  return (
    <li
      aria-selected={isSelected}
      className={cl(
        'text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full',
        { 'selected bg-gray-100 dark:bg-gray-800': isSelected }
      )}
      id={'typeahead-item-' + index}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      ref={option.setRefElement}
      role="option"
      tabIndex={-1}
    >
      <div className="flex items-center text-xs 3xl:text-sm w-full whitespace-nowrap">
        <span className="mr-auto font-medium block truncate">
          {option.title}
        </span>
      </div>

      <div className="mt-1 text-2xs 3xl:text-xs text-gray-500 dark:text-gray-400">
        {option.description}
      </div>
    </li>
  );
}

export default CommandsMenuItem;
