import { useCallback } from 'react';
import {
  LexicalTypeaheadMenuPlugin,
  MenuTextMatch,
  useBasicTypeaheadTriggerMatch
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { TextNode } from 'lexical';
import isEmpty from 'lodash/isEmpty';

import { ID } from '../../../../types';

import { useMountEffect } from '../../../../common/hooks/useMountEffect';

import { $createMentionNode } from '../../utils/MentionNode';
import { MentionsMenuItem } from '../MentionsMenuItem';
import { PopoverWithPortal } from '../../../Popover';
import { MentionTypeaheadOption } from '../../utils/MentionTypeaheadOption';

import { mentionsPluginInitializeMentions } from './utils/mentionsPluginInitializeMentions';

import { PopoverPlacement } from '../../../Popover/popoverConstants';

const PUNCTUATION =
  '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
const NAME = '\\b[A-Z][^\\s' + PUNCTUATION + ']';

const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION
};

const PUNC = DocumentMentionsRegex.PUNCTUATION;

const TRIGGERS = ['@'].join('');

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = '[^' + TRIGGERS + PUNC + '\\s]';

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  '(?:' +
  '\\.[ |$]|' + // E.g. "r. " in "Mr. Smith"
  ' |' + // E.g. " " in "Josh Duck"
  '[' +
  PUNC +
  ']|' + // E.g. "-' in "Salier-Hellendag"
  ')';

const LENGTH_LIMIT = 75;

const AtSignMentionsRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    '((?:' +
    VALID_CHARS +
    VALID_JOINS +
    '){0,' +
    LENGTH_LIMIT +
    '})' +
    ')$'
);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    '((?:' +
    VALID_CHARS +
    '){0,' +
    ALIAS_LENGTH_LIMIT +
    '})' +
    ')$'
);

function checkForAtSignMentions(
  text: string,
  minMatchLength: number
): MenuTextMatch | null {
  let match = AtSignMentionsRegex.exec(text);

  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  }

  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];

    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2]
      };
    }
  }
  return null;
}

interface MentionsPluginProps {
  options: MentionTypeaheadOption[];
  initialMentionIds?: ID[];
  onQueryChange: (matchingString: string) => void;
}

function MentionsPlugin({
  options,
  initialMentionIds,
  onQueryChange
}: MentionsPluginProps) {
  const [editor] = useLexicalComposerContext();

  useMountEffect(() => {
    if (isEmpty(initialMentionIds)) {
      return;
    }

    mentionsPluginInitializeMentions({
      editor,
      options,
      initialMentionIds
    });
  });

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0
  });

  const onSelectOption = useCallback<
    (
      option: MentionTypeaheadOption,
      textNodeContainingQuery: TextNode,
      closeMenu: () => void
    ) => void
  >(
    (selectedOption, nodeToReplace, closeMenu) => {
      editor.update(() => {
        const mentionNode = $createMentionNode(
          selectedOption.id,
          `@${selectedOption.name}`
        );

        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }

        mentionNode.select();
        closeMenu();
      });
    },
    [editor]
  );

  const checkForMentionMatch = useCallback<(text: string) => MenuTextMatch>(
    (text) => {
      const mentionMatch = checkForAtSignMentions(text, 0);

      const slashMatch = checkForSlashTriggerMatch(text, editor);

      return !slashMatch && mentionMatch ? mentionMatch : null;
    },
    [checkForSlashTriggerMatch, editor]
  );

  return (
    <LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
      anchorClassName="reset-menu-anchor"
      onQueryChange={onQueryChange}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      options={options}
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
      ) =>
        anchorElementRef && options?.length ? (
          <PopoverWithPortal
            className="min-w-48 z-20 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700 max-h-60"
            placement={PopoverPlacement.TOP_START}
            referenceElement={anchorElementRef.current}
          >
            <ul>
              {options.map((option, i: number) => (
                <MentionsMenuItem
                  index={i}
                  isSelected={selectedIndex === i}
                  onClick={() => {
                    setHighlightedIndex(i);
                    selectOptionAndCleanUp(option);
                  }}
                  onMouseEnter={() => {
                    setHighlightedIndex(i);
                  }}
                  key={option.key}
                  option={option}
                />
              ))}
            </ul>
          </PopoverWithPortal>
        ) : null
      }
    />
  );
}

export default MentionsPlugin;
