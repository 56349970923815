import { MessageResultVersion, MessageUUID } from '../../messagesTypes';
import { UpdateMessageResultVersionRecordType } from '../../queries/updateMessageResultVersion.query';
import { UpdateItemsCacheKeys } from '../../../../types/updateItemsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface UpdateMessageResultVersionOptions {
  cacheKeys: UpdateItemsCacheKeys;
  query: string;
}

interface UpdateMessageResultVersionInput {
  uuid: MessageUUID;
  resultVersion: MessageResultVersion;
}

interface UpdateMessageResultVersionError {
  fullMessages: string[] | null;
}

interface UpdateMessageResultVersionResponse<UpdateMessageResultVersionRecord> {
  updateMessageResultVersion: {
    status: string;
    record: UpdateMessageResultVersionRecord;
    errors: UpdateMessageResultVersionError;
  };
}

const action = 'updateMessageResultVersion';

function useUpdateMessageResultVersion<
  UpdateMessageResultVersionRecord extends UpdateMessageResultVersionRecordType
>({ cacheKeys, query }: UpdateMessageResultVersionOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateMessageResultVersionInput,
    UpdateMessageResultVersionResponse<UpdateMessageResultVersionRecord>,
    UpdateMessageResultVersionError,
    UpdateMessageResultVersionRecord
  >({
    action,
    cacheKeys,
    query
  });

  return {
    updateMessageResultVersionData: updateQueryData,
    updateMessageResultVersionError: updateQueryError,
    updateMessageResultVersionLoading: updateQueryLoading,
    updateMessageResultVersionErrorMessage: updateQueryErrorMessage,
    updateMessageResultVersion: updateQuery,
    updateMessageResultVersionReset: updateQueryReset
  };
}

export default useUpdateMessageResultVersion;
