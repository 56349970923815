import cl from 'classnames';

import { ImageItemImageVersions } from '../../../ImageHelper';

import { Files } from '../../../../utils/Files';
import { MentionTypeaheadOption } from '../../utils/MentionTypeaheadOption';

interface MentionsMenuItemProps {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: MentionTypeaheadOption;
}

function MentionsMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option
}: MentionsMenuItemProps) {
  return (
    <li
      aria-selected={isSelected}
      className={cl(
        'flex dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm items-center',
        { 'selected bg-gray-100 dark:bg-gray-800': isSelected }
      )}
      id={'typeahead-item-' + index}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      ref={option.setRefElement}
      role="option"
      tabIndex={-1}
    >
      <div className="flex items-center">
        <img
          alt={option.name}
          className="rounded-full h-8 w-8"
          src={Files.image(
            { file: option.image },
            ImageItemImageVersions.Thumb160x160
          )}
        />
        <span className="pl-2 text">{option.name}</span>
      </div>
    </li>
  );
}
export default MentionsMenuItem;
