import {
  MessageBody,
  MessageHours,
  MessageMentionedUserIDs,
  MessageMinutes
} from '../../../messages/messagesTypes';

import {
  TaskFileAttachmentIds,
  TaskGqlError,
  TaskUUID,
  UpdateTaskGqlQuery,
  UpdateTaskGqlStatus,
  UpdateTaskCacheKeys,
  TaskNanoID
} from '../../tasksTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface SubmitResultsToTaskOptions {
  query: UpdateTaskGqlQuery;
  cacheKeys?: UpdateTaskCacheKeys;
}

export interface SubmitResultsToTaskResponse<SubmitResultsToTaskRecordType> {
  submitResultsToTask: {
    status: UpdateTaskGqlStatus;
    recordUuid: TaskUUID;
    recordNanoId: TaskNanoID;
    record: SubmitResultsToTaskRecordType;
    errors: SubmitResultsToTaskError;
  };
}

export interface SubmitResultsToTaskInput {
  uuid: TaskUUID | TaskNanoID;
  fileAttachmentIds: TaskFileAttachmentIds;
  hours: MessageHours;
  minutes: MessageMinutes;
  body: MessageBody;
  mentionedUserIds?: MessageMentionedUserIDs;
}

export interface SubmitResultsToTaskError {
  fullMessages: TaskGqlError;
}

const action = 'submitResultsToTask';

function useSubmitResultsToTask<SubmitResultsToTaskRecordType>({
  query,
  cacheKeys
}: SubmitResultsToTaskOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    SubmitResultsToTaskInput,
    SubmitResultsToTaskResponse<SubmitResultsToTaskRecordType>,
    SubmitResultsToTaskError,
    SubmitResultsToTaskRecordType
  >({ action, cacheKeys, query });

  return {
    submitResultsToTaskData: updateQueryData,
    submitResultsToTaskError: updateQueryError,
    submitResultsToTaskLoading: updateQueryLoading,
    submitResultsToTaskErrorMessage: updateQueryErrorMessage,
    submitResultsToTask: updateQuery,
    submitResultsToTaskReset: updateQueryReset
  };
}

export default useSubmitResultsToTask;
