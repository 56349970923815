import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getRoot,
  $isParagraphNode,
  BLUR_COMMAND,
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_NORMAL,
  FOCUS_COMMAND,
  KEY_DOWN_COMMAND
} from 'lexical';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';

interface ActionsPluginProps {
  onBlur?: (e: FocusEvent) => void;
  onFocus?: (e: FocusEvent) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  value: string;
}
function ActionsPlugin({
  value,
  onKeyDown,
  onFocus,
  onBlur
}: ActionsPluginProps) {
  const [editor] = useLexicalComposerContext();

  const [isEditorEmpty, setIsEditorEmpty] = useState<boolean>(true);

  useEffect(
    () =>
      editor.registerCommand(
        FOCUS_COMMAND,
        (event) => {
          // This function is never called after a YJS event from another connected user
          onFocus?.(event);

          return false;
        },
        COMMAND_PRIORITY_EDITOR
      ),
    [editor, onFocus]
  );

  useEffect(
    () =>
      editor.registerCommand(
        BLUR_COMMAND,
        (event) => {
          // This function is never called after a YJS event from another connected user
          onBlur?.(event);

          return false;
        },
        COMMAND_PRIORITY_EDITOR
      ),
    [editor, onBlur]
  );

  useEffect(
    () =>
      editor.registerCommand(
        KEY_DOWN_COMMAND,
        (event) => {
          onKeyDown?.(event);

          return false;
        },
        COMMAND_PRIORITY_NORMAL
      ),
    [editor, onKeyDown]
  );

  useEffect(
    () =>
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          const root = $getRoot();
          const children = root.getChildren();

          if (children?.length > 1) {
            setIsEditorEmpty(false);
            return;
          }

          if ($isParagraphNode(children?.[0])) {
            setIsEditorEmpty(children[0].getChildren()?.length === 0);
            return;
          }

          setIsEditorEmpty(false);
        });
      }),
    [editor]
  );

  useEffect(() => {
    if (isEmpty(value) && !isEditorEmpty) {
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    }
  }, [value, editor, isEditorEmpty]);

  return <ClearEditorPlugin />;
}

export default ActionsPlugin;
