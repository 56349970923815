import { TeamNanoID } from '../teams/teamsTypes';

export class TeamsUserCache {
  static teamTeamsUsersCacheKey(teamNanoID: TeamNanoID) {
    return `team-${teamNanoID}-teams-users`;
  }

  static selectedTeamsUserSelectFieldCacheKey(fieldName: string) {
    return `teams-user-select-field-${fieldName}`;
  }
}
