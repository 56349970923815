import {
  UUID,
  CreatedAt,
  FetchItemsSort,
  FetchItemsPage,
  FetchItemsLimit,
  FetchItemsGqlQuery,
  FetchItemsCacheKey,
  ID,
  UUIDFilter,
  NanoIDFilter,
  FetchItemGqlQuery,
  FetchItemCacheKey,
  FetchItemsFetched,
  FetchItemsErrorMessage,
  FetchItemsIsPlaceholderData,
  FetchItemsTotalCount,
  FetchItemsFilterItems,
  FetchItemsClearItemsFilters,
  FetchItemsSortItems,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsEditItem,
  FetchItemFetched,
  FetchItemIsPlaceholderData,
  UpdateItemGqlQuery,
  UpdateItemCacheKeys,
  UpdateItemGqlStatus,
  ItemGqlError,
  StringFilter,
  DeleteItemGqlStatus,
  DeleteItemGqlQuery,
  DeleteItemCacheKeys
} from '../../types';

import {
  ProjectActiveTasksCount,
  ProjectBurning,
  ProjectCreatedAt,
  ProjectDebt,
  ProjectDescription,
  ProjectEstimateCost,
  ProjectID,
  ProjectImplementationDate,
  ProjectMembersFinanceRoleName,
  ProjectMembersFullName,
  ProjectMembersImageFile,
  ProjectMembersImageUUID,
  ProjectMembersNanoID,
  ProjectMembersRoleName,
  ProjectMembersUUID,
  ProjectName,
  ProjectNanoID,
  ProjectPerformedTasksCount,
  ProjectProjectAccount,
  ProjectSetDate,
  ProjectStatus,
  ProjectTasksAmountsSum,
  ProjectTasksClientPrepaymentsSum,
  ProjectTasksPrepaymentsSum,
  ProjectUserFinanceRoleName,
  ProjectUserFullName,
  ProjectUserImageFile,
  ProjectUserImageUUID,
  ProjectUserNanoID,
  ProjectUserRoleName,
  ProjectUserUUID,
  ProjectUUID,
  ProjectUserClient,
  ProjectMembersClient,
  ProjectUserCurrentTeamNanoID,
  ProjectMembersCurrentTeamNanoID,
  ProjectUserID,
  ProjectMembersID,
  ProjectTeamImageFile
} from '../projects/projectsTypes';

import {
  UserUUID,
  UserFullName,
  UserImageFile,
  UserImageUUID,
  UserID,
  UserNanoID,
  UserFinanceRoleName,
  UserClient,
  UserCurrentTeamNanoID,
  UserBlocked
} from '../users/usersTypes';
import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';

export type ProjectMemberInviteID = ID;
export type ProjectMemberInviteUUID = UUID;
export type ProjectMemberInviteCreatedAt = CreatedAt;

export type ProjectMemberInviteUserID = UserID;
export type ProjectMemberInviteUserUUID = UserUUID;
export type ProjectMemberInviteUserNanoID = UserNanoID;
export type ProjectMemberInviteUserFullName = UserFullName;
export type ProjectMemberInviteUserImageUUID = UserImageUUID;
export type ProjectMemberInviteUserImageFile = UserImageFile;
export type ProjectMemberInviteUserFinanceRoleName = UserFinanceRoleName;
export type ProjectMemberInviteUserClient = UserClient;
export type ProjectMemberInviteUserBlocked = UserBlocked;
export type ProjectMemberInviteUserCurrentTeamNanoID = UserCurrentTeamNanoID;
export type ProjectMemberInviteProjectID = ProjectID;
export type ProjectMemberInviteProjectUUID = ProjectUUID;
export type ProjectMemberInviteProjectNanoID = ProjectNanoID;
export type ProjectMemberInviteProjectName = ProjectName;
export type ProjectMemberInviteProjectDescription = ProjectDescription;
export type ProjectMemberInviteProjectCreatedAt = ProjectCreatedAt;
export type ProjectMemberInviteProjectSetDate = ProjectSetDate;
export type ProjectMemberInviteProjectImplementationDate =
  ProjectImplementationDate;
export type ProjectMemberInviteProjectBurning = ProjectBurning;
export type ProjectMemberInviteProjectStatus = ProjectStatus;
export type ProjectMemberInviteProjectActiveTasksCount =
  ProjectActiveTasksCount;
export type ProjectMemberInviteProjectPerformedTasksCount =
  ProjectPerformedTasksCount;
export type ProjectMemberInviteProjectProjectAccount = ProjectProjectAccount;
export type ProjectMemberInviteProjectDebt = ProjectDebt;
export type ProjectMemberInviteProjectEstimateCost = ProjectEstimateCost;
export type ProjectMemberInviteProjectTasksAmountsSum = ProjectTasksAmountsSum;
export type ProjectMemberInviteProjectTasksPrepaymentsSum =
  ProjectTasksPrepaymentsSum;
export type ProjectMemberInviteProjectTasksClientPrepaymentsSum =
  ProjectTasksClientPrepaymentsSum;
export type ProjectMemberInviteProjectUserUUID = ProjectUserUUID;
export type ProjectMemberInviteProjectUserID = ProjectUserID;
export type ProjectMemberInviteProjectUserNanoID = ProjectUserNanoID;
export type ProjectMemberInviteProjectUserFullName = ProjectUserFullName;
export type ProjectMemberInviteProjectUserRoleName = ProjectUserRoleName;
export type ProjectMemberInviteProjectUserFinanceRoleName =
  ProjectUserFinanceRoleName;
export type ProjectMemberInviteProjectUserImageUUID = ProjectUserImageUUID;
export type ProjectMemberInviteProjectUserImageFile = ProjectUserImageFile;
export type ProjectMemberInviteProjectUserClient = ProjectUserClient;
export type ProjectMemberInviteProjectUserCurrentTeamNanoID =
  ProjectUserCurrentTeamNanoID;
export type ProjectMemberInviteProjectMembersUUID = ProjectMembersUUID;
export type ProjectMemberInviteProjectMembersID = ProjectMembersID;
export type ProjectMemberInviteProjectMembersNanoID = ProjectMembersNanoID;
export type ProjectMemberInviteProjectMembersFullName = ProjectMembersFullName;
export type ProjectMemberInviteProjectMembersRoleName = ProjectMembersRoleName;
export type ProjectMemberInviteProjectMembersFinanceRoleName =
  ProjectMembersFinanceRoleName;
export type ProjectMemberInviteProjectMembersImageUUID =
  ProjectMembersImageUUID;
export type ProjectMemberInviteProjectMembersImageFile =
  ProjectMembersImageFile;
export type ProjectMemberInviteProjectMembersClient = ProjectMembersClient;
export type ProjectMemberInviteProjectMembersCurrentTeamNanoID =
  ProjectMembersCurrentTeamNanoID;
export type ProjectMemberInviteProjectTeamName = ProjectName;
export type ProjectMemberInviteProjectTeamNanoID = ProjectNanoID;
export type ProjectMemberInviteProjectTeamImageFile = ProjectTeamImageFile;

export enum ProjectMemberInviteFields {
  ID = 'id',
  UUID = 'uuid',
  PROJECT_NAME = 'projectName',
  CREATED_AT = 'createdAt'
}

export type ProjectMemberInviteProjectNameFilter = StringFilter;
export type ProjectMemberInviteInvitedUserNanoIdFilter = NanoIDFilter;
export type ProjectMemberInviteProjectUuidFilter = UUIDFilter;
export type ProjectMemberInviteProjectNanoIdFilter = NanoIDFilter;
export type ProjectMemberInviteTaskStatusNeFilter = ProjectStatus[];
export type ProjectMemberInviteCreatedAtFilter = StringFilter;

export interface FetchProjectMemberInvitesFilters {
  projectName?: ProjectMemberInviteProjectNameFilter;
  invitedUserNanoId?: ProjectMemberInviteInvitedUserNanoIdFilter;
  projectUuid?: ProjectMemberInviteProjectUuidFilter;
  projectNanoId?: ProjectMemberInviteProjectNanoIdFilter;
  projectStatusNe?: ProjectMemberInviteTaskStatusNeFilter;
  createdAt?: ProjectMemberInviteCreatedAtFilter;
}

export type FetchProjectMemberInvitesFiltersFunc = (
  nextFilters: FetchProjectMemberInvitesFilters
) => void;

export enum FetchProjectMemberInvitesSortableFields {
  ID = 'id',
  CREATED_AT = 'createdAt'
}

export enum FetchProjectMemberInvitesSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchProjectMemberInvitesGqlQuery = FetchItemsGqlQuery;
export type FetchProjectMemberInviteGqlQuery = FetchItemGqlQuery;
export type FetchProjectMemberInvitesCacheKey = FetchItemsCacheKey;
export type FetchProjectMemberInviteCacheKey = FetchItemCacheKey;
export type FetchToggleProjectMemberInviteFavoriteCacheKey = FetchItemCacheKey;

export type FetchProjectMemberInvitesFetched = FetchItemsFetched;
export type FetchProjectMemberInvitesErrorMessage = FetchItemsErrorMessage;
export type FetchProjectMemberInvitesIsPlaceholderData =
  FetchItemsIsPlaceholderData;
export type FetchProjectMemberInvitesPage = FetchItemsPage;
export type FetchProjectMemberInvitesLimit = FetchItemsLimit;
export type FetchProjectMemberInvitesSort = FetchItemsSort; // FetchProjectMemberInvitesSortTypes[];
export type FetchProjectMemberInvitesTotalCount = FetchItemsTotalCount;
export type FetchProjectMemberInvitesFilterProjectMemberInvites =
  FetchItemsFilterItems<FetchProjectMemberInvitesFilters>;
export type FetchProjectMemberInvitesClearProjectMemberInvitesFilters =
  FetchItemsClearItemsFilters;
export type FetchProjectMemberInvitesSortProjectMemberInvites =
  FetchItemsSortItems;
export type FetchProjectMemberInvitesPaginateProjectMemberInvites =
  FetchItemsPaginateItems;
export type FetchProjectMemberInvitesPrefetchProjectMemberInvites =
  FetchItemsPrefetchItems;
export type FetchProjectMemberInvitesShowProjectMemberInvite =
  FetchItemsShowItem;
export type FetchProjectMemberInvitesEditProjectMemberInvite =
  FetchItemsEditItem;

export type FetchProjectMemberInviteFetched = FetchItemFetched;
export type FetchProjectMemberInviteIsPlaceholderData =
  FetchItemIsPlaceholderData;

export type FetchProjectMemberInvitesChecked = Checked;
export type FetchProjectMemberInvitesCheckedHash = CheckedHashItem;
export type FetchProjectMemberInvitesOnSetCheckedIds = OnSetCheckedIds;
export type FetchProjectMemberInvitesCheckedAll = CheckedAll;
export type FetchProjectMemberInvitesOnCheckAll = OnCheckAll;

export type DeclineProjectMemberInviteGqlError = ItemGqlError;
export type DeclineProjectMemberInviteGqlStatus = UpdateItemGqlStatus;
export type DeclineProjectMemberInviteGqlQuery = UpdateItemGqlQuery;
export type DeclineProjectMemberInviteCacheKeys = UpdateItemCacheKeys;

export type UpdateProjectMemberInviteGqlQuery = UpdateItemGqlQuery;
export type UpdateProjectMemberInviteCacheKeys = UpdateItemCacheKeys;
export type UpdateProjectMemberInviteGqlStatus = UpdateItemGqlStatus;
export type UpdateProjectMemberInviteGqlError = ItemGqlError;

export type DeleteProjectMemberInviteGqlError = ItemGqlError;
export type DeleteProjectMemberInviteGqlStatus = DeleteItemGqlStatus;
export type DeleteProjectMemberInviteGqlQuery = DeleteItemGqlQuery;
export type DeleteProjectMemberInviteCacheKeys = DeleteItemCacheKeys;
