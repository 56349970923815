import {
  FetchTeamsUsersFilters,
  FetchTeamsUsersSort,
  FetchTeamsUsersPage,
  FetchTeamsUsersLimit
} from '../../teamsUsersTypes';

import { InfiniteIndexQueryBaseNodeType } from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

import {
  INITIAL_TEAMS_USERS_FILTERS,
  INITIAL_TEAMS_USERS_LIMIT,
  INITIAL_TEAMS_USERS_SORT
} from '../../teamsUsersConstants';
import { useFinInfiniteIndexQuery } from '../../../common/hooks/base/reactQuery/useFinInfiniteIndexQuery';

interface TeamsUsersOptions {
  cacheKey: string;
  initialFilters?: FetchTeamsUsersFilters;
  initialLimit?: FetchTeamsUsersLimit;
  initialPage?: FetchTeamsUsersPage;
  initialSort?: FetchTeamsUsersSort;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    withoutPrefetch?: boolean;
  };
  query: string;
}

const scope = 'teamsUsers';

function useTeamsUsers<ItemType extends InfiniteIndexQueryBaseNodeType>({
  cacheKey,
  query,
  initialFilters = INITIAL_TEAMS_USERS_FILTERS,
  initialSort = INITIAL_TEAMS_USERS_SORT,
  initialLimit = INITIAL_TEAMS_USERS_LIMIT,
  options = {}
}: TeamsUsersOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    limitItems
  } = useFinInfiniteIndexQuery<ItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    options,
    query,
    scope
  });

  return {
    teamsUsersData: data,
    teamsUsers: items,
    teamsUsersError: itemsError,
    teamsUsersTotalCount: itemsTotalCount,
    teamsUsersFetched: isFetched,
    teamsUsersLoading: isLoading,
    teamsUsersFetchingNextPage: isFetchingNextPage,
    teamsUsersIsPlaceholderData: isPlaceholderData,
    teamsUsersFilters: currentFilters,
    teamsUsersSort: currentSort,
    teamsUsersPage: currentPage,
    teamsUsersLimit: currentLimit,
    hasNextTeamsUsersPage: hasNextPage,
    updateTeamsUserCache: updateItemCache,
    loadMoreTeamsUsers: loadMoreItems,
    filterTeamsUsers: filterItems,
    changeTeamsUsersFilters: changeItemsFilters,
    clearTeamsUsersFilters: clearItemsFilters,
    sortTeamsUsers: sortItems,
    limitTeamsUsers: limitItems
  };
}

export default useTeamsUsers;
