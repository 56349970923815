import { useCallback, useMemo } from 'react';
import { InitialConfigType } from '@lexical/react/LexicalComposer';
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  TRANSFORMERS
} from '@lexical/markdown';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { EditorState, LexicalEditor, LineBreakNode } from 'lexical';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
import isEmpty from 'lodash/isEmpty';

import { ClassName, IsDisabled } from '../../../../types';

import {
  LexicalFieldValueType,
  OnChangeLexicalFieldValue
} from '../../../FormFields/LexicalEditorField/LexicalEditorField';

import { MentionNode } from '../../utils/MentionNode';

const LexicalEditorNodes = [
  AutoLinkNode,
  CodeNode,
  CodeHighlightNode,
  HeadingNode,
  LinkNode,
  ListNode,
  ListItemNode,
  MentionNode,
  QuoteNode,
  LineBreakNode
];

const lexicalEditorInitialConfig = (
  root: ClassName = 'markdown markdown-sm p-2 bg-gray-200 dark:bg-gray-800 text-sm rounded-md min-h-[2rem] focus:ring-4 outline-none h-full overflow-y-scroll'
) => ({
  namespace: 'lexical-editor',
  nodes: LexicalEditorNodes,
  theme: {
    // root: 'markdown markdown-sm sm:markdown-md markdown-pre:whitespace-pre-wrap overflow-y-scroll w-full max-h-500 pr-2 pl-4 py-2.5 bg-gray-200 dark:bg-gray-800 text-sm leading-snug outline-none border-none resize-none',
    root,
    link: 'cursor-pointer',
    code: 'bg-inherit text-inherit before:content-[""] after:content-[""]',
    text: {
      bold: 'font-semibold',
      underline: 'underline',
      italic: 'italic',
      strikethrough: 'line-through',
      underlineStrikethrough: 'underlined-line-through'
    },
    list: {
      ul: '*:my-0',
      ol: '*:my-0'
    }
  },
  onError: (error: Error) => {
    console.log(error);
  }
});

interface LexicalEditorHelperOptions {
  disabled?: IsDisabled;
  onChange?: OnChangeLexicalFieldValue;
  value: LexicalFieldValueType;
  inputClassName?: ClassName;
}
function useLexicalEditorHelper({
  disabled,
  onChange,
  value,
  inputClassName
}: LexicalEditorHelperOptions) {
  const lexicalEditorConfig = useMemo<InitialConfigType>(
    () => ({
      ...lexicalEditorInitialConfig(inputClassName),
      editorState: () => {
        $convertFromMarkdownString(value?.text, TRANSFORMERS);
      },
      editable: !disabled
    }),
    [disabled, inputClassName, value?.text]
  );

  const handleChange = useCallback<
    (editorState: EditorState, editor: LexicalEditor, tags: Set<string>) => void
  >(
    (editorState) => {
      editorState.read(() => {
        const mentionNodeIds = [...editorState._nodeMap.values()]
          .filter((node): node is MentionNode => node.__type === 'mention')
          .map((node) => node.__id);

        const mentionIds = isEmpty(mentionNodeIds) ? undefined : mentionNodeIds;

        const markdown = $convertToMarkdownString(TRANSFORMERS) || undefined;

        const newValue =
          markdown || mentionIds ? { text: markdown, mentionIds } : undefined;

        onChange?.(newValue);
      });
    },
    [onChange]
  );
  return {
    lexicalEditorConfig,
    handleChange
  };
}

export default useLexicalEditorHelper;
