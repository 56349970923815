import { FetchItemCacheKey, FetchItemsGqlQuery } from '../../../../types';

import {
  FetchTaskMemberInvitesGqlQuery,
  FetchTaskMemberInvitesCacheKey,
  FetchTaskMemberInvitesFilters,
  FetchTaskMemberInvitesSort,
  FetchTaskMemberInvitesPage,
  FetchTaskMemberInvitesLimit
} from '../../taskMemberInvitesTypes';

import { useReadIndexQuery } from '../../../common/hooks/base/reactQuery/useReadIndexQuery';

import {
  INITIAL_TASK_MEMBER_INVITES_FILTERS,
  INITIAL_TASK_MEMBER_INVITES_LIMIT,
  INITIAL_TASK_MEMBER_INVITES_PAGE,
  INITIAL_TASK_MEMBER_INVITES_SORT
} from '../../taskMemberInvitesConstants';

interface TaskMemberInvitesDefaultOptions {
  cacheKey: FetchTaskMemberInvitesCacheKey;
  query: FetchTaskMemberInvitesGqlQuery;
  initialFilters?: FetchTaskMemberInvitesFilters;
  initialSort?: FetchTaskMemberInvitesSort;
  initialPage?: FetchTaskMemberInvitesPage;
  initialLimit?: FetchTaskMemberInvitesLimit;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    withoutPrefetch?: boolean;
  };
}

interface TaskMemberInvitesWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemsGqlQuery;
}

interface TaskMemberInvitesWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

type TaskMemberInvitesOptions = TaskMemberInvitesDefaultOptions &
  (
    | TaskMemberInvitesWithPrefetchItemOptions
    | TaskMemberInvitesWithoutPrefetchItemOptions
  );

const scope = 'taskMemberInvites';

function usePaginatedTaskMemberInvites<ItemType>({
  cacheKey,
  query,
  initialFilters = INITIAL_TASK_MEMBER_INVITES_FILTERS,
  initialSort = INITIAL_TASK_MEMBER_INVITES_SORT,
  initialPage = INITIAL_TASK_MEMBER_INVITES_PAGE,
  initialLimit = INITIAL_TASK_MEMBER_INVITES_LIMIT,
  fetchItemCacheKey,
  fetchItemQuery,
  options = {}
}: TaskMemberInvitesOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems,
    prefetchItem
  } = useReadIndexQuery<ItemType>({
    scope,
    query,
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    fetchItemCacheKey,
    fetchItemQuery,
    options
  });

  return {
    taskMemberInvitesData: data,
    taskMemberInvites: items,
    taskMemberInvitesError: itemsError,
    taskMemberInvitesTotalCount: itemsTotalCount,
    taskMemberInvitesFetched: isFetched,
    taskMemberInvitesLoading: isLoading,
    taskMemberInvitesIsPlaceholderData: isPlaceholderData,
    taskMemberInvitesFilters: currentFilters,
    taskMemberInvitesSort: currentSort,
    taskMemberInvitesPage: currentPage,
    taskMemberInvitesLimit: currentLimit,
    filterTaskMemberInvites: filterItems,
    changeTaskMemberInvitesFilters: changeItemsFilters,
    clearTaskMemberInvitesFilters: clearItemsFilters,
    sortTaskMemberInvites: sortItems,
    paginateTaskMemberInvites: paginateItems,
    limitTaskMemberInvites: limitItems,
    prefetchTaskMemberInvites: prefetchItems,
    prefetchTaskMemberInvite: prefetchItem
  };
}

export default usePaginatedTaskMemberInvites;
