import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';
import {
  FetchItemsCacheKey,
  FetchItemsClearItemsFilters,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  IdFilterType,
  ItemGqlError,
  UpdateItemCacheKeys,
  UpdateItemGqlQuery,
  UpdateItemGqlStatus,
  DeleteItemCacheKeys,
  DeleteItemCacheKey,
  DeleteItemGqlQuery,
  DeleteItemGqlStatus,
  UUID,
  FetchItemFetched,
  FetchItemIsPlaceholderData,
  FetchItemCacheKey,
  FetchItemGqlQuery,
  CreatedAt,
  TextFilterType,
  NanoIdFilterType
} from '../../types';
import { UpdateIndexQueryItemCacheAction } from '../common/hooks/base/reactQuery/useIndexQuery';

import { SaveSearchFilterFunc } from '../searchFilters/searchFiltersTypes';

import {
  UserFullName,
  UserID,
  UserImageFile,
  UserNanoID,
  UserBlocked,
  UserUUID,
  UserClientStatusesEnum,
  UserLastMessageDate,
  UserEmail,
  UserName,
  UserClient
} from '../users/usersTypes';

export type TeamsUserUUID = UUID;
export type TeamsUserCreatedAt = CreatedAt;
export type TeamsUserLibrary = boolean;
export type TeamsUserFinance = boolean;
export type TeamsUserRequirements = boolean;

export type TeamsUserUserID = UserID;
export type TeamsUserUserUUID = UserUUID;
export type TeamsUserUserNanoID = UserNanoID;
export type TeamsUserUserBlocked = UserBlocked;
export type TeamsUserUserFullName = UserFullName;
export type TeamsUserUserName = UserName;
export type TeamsUserUserImageFile = UserImageFile;
export type TeamsUserUserClientStatus = UserClientStatusesEnum;
export type TeamsUserUserLastMessageDate = UserLastMessageDate;
export type TeamsUserUserEmail = UserEmail;
export type TeamsUserUserClient = UserClient;

export type UpdateTeamsUserCacheKeys = UpdateItemCacheKeys;
export type UpdateTeamsUserGqlQuery = UpdateItemGqlQuery;
export type UpdateTeamsUserGqlStatus = UpdateItemGqlStatus;
export type UpdateTeamsUserGqlError = ItemGqlError;
export type DeleteTeamsUserGqlStatus = DeleteItemGqlStatus;
export type DeleteTeamsUserCacheKey = DeleteItemCacheKey;
export type DeleteTeamsUserCacheKeys = DeleteItemCacheKeys;
export type DeleteTeamsUserGqlQuery = DeleteItemGqlQuery;

export type FetchTeamsUsersCacheKey = FetchItemsCacheKey;
export type FetchTeamsUsersGqlQuery = FetchItemsGqlQuery;

export type FetchTeamsUserCacheKey = FetchItemCacheKey;
export type FetchTeamsUserGqlQuery = FetchItemGqlQuery;

export interface FetchTeamsUsersFilters {
  teamId?: IdFilterType;
  teamNanoId?: NanoIdFilterType;
  userFullName?: TextFilterType;
  userFullNameOrEmail?: TextFilterType;
}

export const enum FetchTeamsUsersFilterFields {
  USER_FULL_NAME = 'userFullName',
  USER_FULL_NAME_OR_EMAIL = 'userFullNameOrEmail'
}

export type FetchTeamsUsersClearTeamsUsersFilters = FetchItemsClearItemsFilters;
export type FetchTeamsUsersFilterTeamsUsers =
  FetchItemsFilterItems<FetchTeamsUsersFilters>;
export type FetchTeamsUsersFiltersFunc = (
  nextFilters: FetchTeamsUsersFilters
) => void;
export type TeamsUsersCreateSearchFiltersFunc =
  SaveSearchFilterFunc<FetchTeamsUsersFilters>;

export const enum FetchTeamsUsersSortItem {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC'
}

export type FetchTeamsUsersSort = FetchItemsSort;
export type FetchTeamsUsersSortTeamsUsers = FetchItemsSortItems;
export type FetchTeamsUsersPage = FetchItemsPage;
export type FetchTeamsUsersLimit = FetchItemsLimit;
export type FetchTeamsUsersErrorMessage = FetchItemsErrorMessage;
export type FetchTeamsUsersFetched = FetchItemsFetched;
export type FetchTeamsUserFetched = FetchItemFetched;
export type FetchTeamsUsersIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchTeamsUserIsPlaceholderData = FetchItemIsPlaceholderData;
export type FetchTeamsUsersPaginateTeamsUsers = FetchItemsPaginateItems;
export type FetchTeamsUsersPrefetchTeamsUsers = FetchItemsPrefetchItems;
export type FetchTeamsUsersTotalCount = FetchItemsTotalCount;

export type FetchTeamsUsersCheckedAll = CheckedAll;
export type FetchTeamsUsersOnCheckAll = OnCheckAll;
export type FetchTeamsUsersChecked = Checked;
export type FetchTeamsUsersCheckedHash = CheckedHashItem;
export type FetchTeamsUsersOnSetCheckedIds = OnSetCheckedIds;

export type TeamsUserGqlError = ItemGqlError;

export type UpdatePaginatedTeamsItemCacheAction<ItemType> =
  UpdateIndexQueryItemCacheAction<ItemType>;

export type ChangeTeamsUsersFiltersFunc = (
  changedFilters: FetchTeamsUsersFilters,
  removeFilters?: string[]
) => void;
