import toNumber from 'lodash/toNumber';
import * as yup from 'yup';

import { formsErrors } from '../../../../../../../locales/keys';

import { TaskResultsFields } from '../../../../../tasksTypes';

export const submitResultsToTaskValidationSchema = yup.object({
  [TaskResultsFields.HOURS]: yup
    .number()
    .transform((_, value) =>
      !value || value === '' ? undefined : toNumber(value)
    )
    .max(7, formsErrors.hours.lessEight)
    .min(0, formsErrors.hours.nonNegative)
    .nullable(true),
  [TaskResultsFields.MINUTES]: yup
    .number()
    .transform((_, value) => (value === '' ? undefined : toNumber(value)))
    .max(59, formsErrors.minutes.lessSixty)
    .min(0, formsErrors.minutes.nonNegative)
    .nullable(true),
  [TaskResultsFields.FILE_ATTACHMENT_IDS]: yup
    .array()
    .min(1, formsErrors.required)
});
